import React, { useEffect, useState } from 'react';
import WorkflowsTable from '../../Components/WorkflowsTable/WorkflowsTable';
import { getStuckWorkflows } from '../../api/api';
import { Workflow } from '../../types';
import { SpaceBetween } from '@amzn/awsui-components-react';

const StuckWorkflowsTable : React.FC = () => {

    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>("");
    const [refreshCnt, setRefreshCnt] = useState<number>(0);
    const [workflows, setWorkflows] = useState<Workflow[] | undefined>(undefined);
    const [failedAccounts, setFailedAccounts] = useState<string[] | undefined>(undefined);

    useEffect(() => {
        setLoading(true);
        setError("");
        setFailedAccounts(undefined);
        getStuckWorkflows().then(response => {
            setWorkflows(response.workflows);
            setFailedAccounts(response.failedAccounts);
        }).catch(error => {
            console.error(error);
            setError("Could not load stuck workflows");
        }).finally(() => {
            setLoading(false);
        })
    }, [refreshCnt]);
    
    return (
        <WorkflowsTable 
            title='Stuck Workflows'
            columns={["workflowName", "brokerId", "cell", "status", "startTime", "endTime"]}
            headerDescription="Cell workflows running longer than 30 min (in last 3 days)"
            warning={failedAccounts && failedAccounts?.length > 0 ? ("Could not fetch data for accounts: " + failedAccounts.join(", ")) : undefined}
            workflows={workflows} filter={false} loading={loading} error={error} onRefresh={() => {
            setRefreshCnt(refreshCnt + 1);
        }}/>
    )

}

export default StuckWorkflowsTable;