import React, { createContext, useEffect, useState } from 'react';
import "@amzn/awsui-global-styles/polaris.css";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import PageNotFound from './PageNotFound';
import BrokerPage from './BrokerPage/BrokerPage';
import MainPage from './MainPage/MainPage';
import Header from './Header/Header';
import { ApplicationState, Broker, Config, Region } from './types';
import { getApiClient } from './api/client';
import { getRegions } from './utils';
import { InvalidIdentityTokenException } from '@aws-sdk/client-sts';


export const AppContext = createContext<ApplicationState>(createDefaultAppState());

function createDefaultAppState(): ApplicationState {
    return {
        currentBroker: undefined,
        setCurrentBroker: (broker: Broker | undefined) => void {}
    }
}

const App : React.FC = () => {

    const [config, setConfig] = useState<Config | undefined>(undefined);
    const [regions, setRegions] = useState<Region[] | undefined>(undefined);
    const [currentBroker, setCurrentBroker] = useState<Broker | undefined>(undefined);

    useEffect(() => {
        getApiClient().then(api => {
            setConfig({
                username: api.user
            });
        }).catch(error => {
            console.error(error);
            if (error instanceof InvalidIdentityTokenException) {
                // this sometimes happens affter Midway token expires and user is redirected to Midway login screen.
                console.log("Invalid token, redirecting to home page.");   
                window.location.reload();
                return;
            }
        })

        getRegions().then(regions => {
            setRegions(regions);
        }).catch(error => {
            console.error(error);
        })

    }, [])

    const state : ApplicationState = {
        currentBroker: currentBroker,
        setCurrentBroker: setCurrentBroker
    }

    if (config === undefined || regions === undefined) {
        return <></>
    }

    return (
        <div>
            <AppContext.Provider value={state}>
                <BrowserRouter>
                    <Header config={config} regions={regions} />
                    <Routes>
                        <Route path="/" element={<MainPage />} />
                        <Route path="/broker/:brokerId" element={<BrokerPage />} />
                        <Route path="/broker/:brokerId/:subPage/:serviceMetricsTab" element={<BrokerPage />} />
                        <Route path="/broker/:brokerId/:subPage/*" element={<BrokerPage />} />
                        <Route path="*" element={<PageNotFound />} />
                    </Routes>
                </BrowserRouter>
            </AppContext.Provider>
        </div>
    )
}

export default App;